<template>
<div class="card card-height-100">
    <div class="card-body">
        <div class="d-flex mb-4 align-items-center justify-content-between">
            <div class="me-3">
                <h5 class="mb-0 card-title">{{item.title}}</h5>
            </div>
            <slot name="actions"></slot>
        </div>
        <div class="mb-4 d-sm-flex align-items-center justify-content-start">
            <div class="me-sm-3">
                <img v-if="item.thumb_path" style="width:3rem;" :src="absoluteUrl(item.thumb_path)" />
                <div v-else class="avatar img-thumbnail me-2 flex-shrink-0">
                    <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                        {{item.title.charAt(0).toUpperCase()}}
                    </div>
                </div>
            </div> 
            <div class="">
                <div class="mb-1">Price: <strong>  ${{item.price}} </strong></div>
                <div>In stock: <strong> {{ item.stock_quantity }}</strong> </div>
            </div>
        </div>
        <div class="">
            <span class="me-3" v-if="item.brand"> <strong>Make: </strong> {{item.brand.title}}</span>  <br> 
            <span v-if="item.category"> <strong>Category: </strong> {{item.category.title}}</span>  <br> 
            <strong>Color:</strong> {{ item.color }} <br> 
            <strong>Condition:</strong> {{ $filters.textFromSlug(item.condition) }} <br> 
            <strong>Storage:</strong> {{ item.storage }} <br>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props:{
        item:{
            type: Object,
            required: true
        }
    },      
}
</script>

<style>

</style>
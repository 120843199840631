
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Products</h5>
      <div class="row mb-4 gy-2">
        <div class="col-sm-12 col-md-6 d-flex">
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <b-button variant="primary" class="" @click.prevent="popupModalShow = true" type="button">
            <i class="ri ri-add-fill align-bottom"></i> Add product </b-button>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search"
             class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="my-3">
				<p class="mb-0">Showing {{paginationInfo}} results</p>
			</div>
      <is-loading v-if="isLoading" />
      <div v-else class="row">
        <div class="col-lg-4 col-sm-6"
          v-for="(item, dIndex) of pageData.data" :key="dIndex">
            <product-card :item="item">
              <template v-slot:actions>
                <div class="dropdown dropstart no-arrow">
                  <button class="btn btn-sm btn-light dropdown-toggle" type="button" role="button" 
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link :to="`/products/${item.id}`" class="dropdown-item"
                      type="button"> View </router-link>
                    </li>
                  </ul>
                </div>
              </template>
            </product-card>
        </div>
      </div>
      <div class="my-4">
        <b-pagination v-model="currentPage" :limit="3" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
      <b-modal size="lg" v-model="popupModalShow" body-class="loading-viewport" hide-footer centered
        title="Add product"
        title-class="fs-6" @hide="initializeAll()">
        <product-form v-if="popupModalShow" @closeMe="initializeAll()"/>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ProductForm from "@/components/forms/ProductForm.vue"
import ProductCard from "@/components/cards/ProductCard.vue"
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';

export default {
  name: "product-list",
  components:{
    ProductForm, ProductCard,
    IsLoading
  },
  data() {
    return {
      isLoading: false,
      popupModalShow: false,
      searchQuery: "",
      pageOptions: [10, 25, 50, 100],
    }
  },
  computed: {
    pageData(){
      return this.$store.state.product.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 25
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
			if(!this.currentPage) return '0';
        return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
        - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
        ? this.currentPage * this.itemsPerPage : this.pageData.total}
        of ${this.pageData.total}`
    },
  },
  methods: {
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("product/fetchProducts", payload)
      .then((_) => this.isLoading = false )
    },
    initializeAll(){
      this.popupModalShow = false;
    },
  },
  created(){
    this.fetchItems()
  },
}
</script>

